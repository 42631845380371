// in development mode, it proxied by webpack-dev-server, automatically add 'localhost:8003'
let apiUrlTemp
let api2UrlTemp
if(process.env.NODE_ENV === 'development') {
  apiUrlTemp = 'http://localhost:9000/api'
  api2UrlTemp = 'http://localhost:9000/api2'
}
if(process.env.NODE_ENV === 'production') {
  // manually add localhost:8003
  apiUrlTemp = 'https://api.instease.com/apiv1'
  api2UrlTemp = 'https://api.instease.com/pay'
}
export const apiUrl = apiUrlTemp
export const api2Url = api2UrlTemp
export const domain="dev-2q20q04nhtnk5p1e.eu.auth0.com"
export const clientId="49r0XySVmsmy73CShWOmoOi2xF7iYiy7"